.Portfolio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#upicon {
  position: fixed;
  bottom: 0;
  width: 30px;
  margin: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

#upicon:hover {
  transform: scale(1.2, 1.2);
}
