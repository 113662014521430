@font-face {
  font-family: 'Anurati';
  src: url('Anurati-Regular.otf');
}

.Navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
}

.Logo {
  font-family: 'Anurati', Arial, sans-serif;
  font-size: 24px;
  padding: 30px;
  letter-spacing: 6px;
  animation: textfade 3s;
}

.sm-logo {
  animation: textfade 3s;
  width: 30px;
  margin-right: 20px;
  transition: all 0.3s ease;
}

.sm-logo:hover {
  transform: scale(1.2, 1.2);
}

@keyframes textfade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
