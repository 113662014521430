.company {
  display: flex;
  margin: auto;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 50px;
  padding: 20px;
}

.blurb {
  display: flex;
  margin: auto;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 35px;
  padding: 20px;
}

.description {
  display: flex;
  margin: auto;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 20px;
  padding: 20px;
}
