.Intro {
  background-color: white;
  color: rgb(28, 28, 28);
  height: 90vh;
  min-height: 1000px;
}

.Intro-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 40px;
  max-width: 700px;
  animation: textfade 3s;
}

.Intro-subtext {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  animation: textfade 3s;
}

#Intro-link-sbux {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 20px;
  color: rgb(40, 96, 68);
  text-decoration: underline;
  cursor: pointer;
  animation: textfade 5s;
}

#Intro-link-brava {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 20px;
  color: rgb(244, 162, 10);
  text-decoration: underline;
  cursor: pointer;
  animation: textfade 5s;
}

#Intro-link-yelp {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 20px;
  color: red;
  text-decoration: underline;
  cursor: pointer;
  animation: textfade 5s;
}

#Intro-link-ebay {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 20px;
  color: rgb(78, 72, 238);
  text-decoration: underline;
  cursor: pointer;
  animation: textfade 5s;
}

#Intro-link-contact {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 20px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  animation: textfade 5s;
}

@keyframes textfade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
